import React, { useState, useRef } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { logo, mpost } from "../../assets/index";
import { useTranslation } from "react-i18next";


const Header = () => {
  const { t, i18n } = useTranslation();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const productsTimeoutRef = useRef(null);
  const servicesTimeoutRef = useRef(null);


  const openProductsMenu = () => {
    if (productsTimeoutRef.current) {
      clearTimeout(productsTimeoutRef.current);
    }
    setIsProductsOpen(true);
  };

  const closeProductsMenu = () => {
    productsTimeoutRef.current = setTimeout(() => { 
      setIsProductsOpen(false); 
    }, 200);
  };

  const openServicesMenu = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };

  const closeServicesMenu = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 200);
  };
  const toggleProductsSubMenu = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  const toggleServicesSubMenu = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);

  

  return (
    <>
      <nav className="bg-white border-b border-gray-200 py-2 ">
        <div className="w-[92%] mx-auto">
          <div className="container mx-auto flex items-center justify-between py-3 ">
            <div>
              <a href='/'> <img src={logo} alt="Logo" className="h-16" /></a>
            </div>  
            <div className="hidden space-x-2 text-[10px] 2xl:space-x-6 2xl:text-base lg:flex"> 
              <a href="/" className="text-[#086170] font-bold">{t("Əsas səhifə")}</a>
              <a href="/about" className="text-[#086170] font-bold">{t("Haqqımızda")}</a>
              <a href="/partners" className="text-[#086170] font-bold">{t("Müştərilərimiz")}</a>
              <a href="/services" className="text-[#086170] font-bold">{t("Xidmətlər")}</a>
              <a href="/portfolio" className="text-[#086170] font-bold">{t("Portfolio")}</a>
              <a href="/blog" className="text-[#086170] font-bold">{t("Blog")}</a>
              <a href="/contact" className="text-[#086170] font-bold">{t("Əlaqə")}</a>
            </div>
            <div className="lg:hidden flex items-center relative ml-auto mr-10">
      <button className="text-[#086170] focus:outline-none" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[#086170]"></i>
      </button>
      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-[390px] z-50 shadow-lg`}>
          <button className="absolute top-5 right-5 text-white bg-[#086170] p-2 rounded" onClick={toggleHamburgerMenu}>
            <FaTimes />
          </button>
          <div className="flex flex-col items-start p-8">
            <a href="/" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Əsas səhifə")}</a>
            <a href="/about" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Haqqımızda")}</a>
            <a href="/partners" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Müştərilərimiz")}</a>
            <a href="/services" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Xidmətlər")}</a>
            <a href="/portfolio" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Portfolio")}</a>
            <a href="/blog" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Blog")}</a>
            <a href="/contact" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Əlaqə")}</a>
          </div>
        </div>
      )}
    </div>
    
            <div className="hidden sml:block"> 
              <a href="https://api.whatsapp.com/send?phone=994516834218&text=">
                <button className="bg-[#086170] text-xs text-white px-3 py-4 rounded font-bold scaling-button">
                  {t("Qiymət təklifi al")}
                </button>
              </a>

              <style jsx>{`
                @keyframes scale {
                  0%, 100% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.1);
                  }
                }
                .scaling-button {
                  animation: scale 1.5s infinite ease-in-out;
                }
              `}</style>
            </div>
          </div>
        </div>
      </nav>            
    </>
  ); 
};

export default Header;



