import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

const services = [
  {
    title: "Vebsaytların hazırlanması",
    description: "Profesional və sürətli vebsayt hazırlama xidmətləri",
    icon: "🌐", // Vebsayt üçün uyğun
    link: "/saytlarin-hazirlanmasi",
  },
  {
    title: "Domen və hostinq satışı",
    description: "Münasib qiymətə etibarlı domen və sürətli hostinq xidmətləri",
    icon: "🖥️", // Server və domen üçün daha uyğun
    link: "/domen-hostinq",
  },
  {
    title: "Google SEO",
    description: "Google SEO ilə axtarış nəticələrində zirvəyə qalxın",
    icon: "⚙️", // SEO üçün uyğun
    link: "/google-seo",
  },
  {
    title: "Vebsaytlara texniki dəstək",
    description: "Vebsaytlar üçün 24/7 texniki dəstək xidmətləri",
    icon: "🛠️", // Texniki dəstək üçün uyğun
    link: "/vebsayt-texniki",
  },
  {
    title: "Korporativ poçt",
    description: "Güvənli və professional korporativ e-poçt həlləri",
    icon: "📧", // Korporativ poçt üçün uyğun
    link: "/korporativ-poct",
  },
  {
    title: "Ödəmə sistemlərinin inteqrasiyası",
    description: "Müxtəlif ödəmə sistemlərinin sürətli inteqrasiyası",
    icon: "💳", // Ödəmə sistemləri üçün uyğun
    link: "/odeme-inteqrasiya",
  },
];


const Services = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="bg-gray-100 p-10">
     <div className='w-full sml:w-[80%] mx-auto'>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {services.map((service, index) => (
          <div data-aos="fade-up" key={index} className="bg-white p-6 rounded-lg shadow-md">
            <div className="text-3xl mb-4">{service.icon}</div>
            <h2 className="text-xl font-semibold mb-2">{t(service.title)}</h2>
            <p className="text-gray-700 mb-4">{t(service.description)}</p>
            <a href={service.link} className="text-blue-500 hover:underline">
              {t("Daha Ətraflı")} →
            </a>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default Services;
