import React from "react";
import { footerLogo } from "../../assets/index";
import { useTranslation } from "react-i18next";

const Footer = () => {

  const { t, i18n } = useTranslation();
  return (
    <>
      <footer className="bg-gray-900 text-gray-300 py-20">
        <div className="w-[90%] mx-auto">
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-20">
            <div className="flex flex-col items-center md:items-start">
              <img src={footerLogo} alt="Prosayt MMC Logo" className="mb-6 w-60 h-24 " />
              <div className="flex space-x-8 mt-8">
                <a href="https://www.instagram.com/prosayt.az/" className="text-[#086170] hover:text-blue-400 transition duration-300"><i className="fab fa-instagram text-2xl"></i></a>
                <a href="https://www.linkedin.com/company/prosayt/" className="text-[#086170] hover:text-blue-400 transition duration-300"><i className="fab fa-linkedin text-2xl"></i></a>
              </div>
            </div>
            <div className="flex flex-col items-center md:items-start">
              <h3 className="text-white font-bold mb-8 text-2xl">{t("Sürətli Keçidlər")}</h3>
              <ul className="space-y-4">
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Əsas səhifə")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/about" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Haqqımızda")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}}  className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/partners" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Müştərilərimiz")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}}  className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/services" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Xidmətlərimiz")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/portfolio" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Portfolio")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/contact" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Əlaqə")}</a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center md:items-start">
              <h3 className="text-white font-bold mb-8 text-2xl">{t("Xidmətlərimiz")}</h3>
              <ul className="space-y-4">
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/saytlarin-hazirlanmasi" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Vebsaytların hazırlanması")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/domen-hostinq" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Domen və hostinq satışı")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/google-seo" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Google SEO")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/vebsayt-texniki" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Vebsaytlara texniki dəstək")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/korporativ-poct" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Korporativ poçt")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/odeme-inteqrasiya" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Ödəmə sistemlərinin inteqrasiyası")}</a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center md:items-start">
              <h3 className="text-white font-bold mb-8 text-2xl">{t("Əlaqə Vasitələri")}</h3>
              <address className="not-italic space-y-4 text-lg">
                <p><i style={{color:'#086170'}} className="fa-solid fa-location-dot mr-3"></i> {t("Bakı şəhəri, AF Mall ticarət mərkəzi, 3-cü mərtəbə")}</p>
                <p><i style={{color:'#086170'}} className="fa-solid fa-envelope mr-3"></i><a href="mailto:info@hesabatmmc.az" className="hover:underline hover:text-gray-100 transition duration-300">prosayt.az@gmail.com</a></p>
                <p><i style={{color:'#086170'}} className="fa-solid fa-phone-flip mr-3"></i><a href="tel:+994516834218" className="hover:underline hover:text-gray-100 transition duration-300">+994 51 683 42 18</a></p>
              </address>
            </div>
          </div>
          <div className="container mx-auto text-center mt-12 border-t border-gray-700 pt-8 flex flex-col md:flex-row justify-between items-center">
            <p>{t("2024 © Müəllif hüquqları qorunur.")}</p>
            <p>{t("Saytın hazırlanması")}: <a href="https://www.prosayt.az" className="hover:underline hover:text-gray-100 transition duration-300">Prosayt.az</a></p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
