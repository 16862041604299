import { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import AboveHeader from "./Components/AboveHeader/AboveHeader";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "../src/Pages/Home/Home";
import About from "./Pages/About/About";
import Services from "./Pages/Services/Services";
import Contact from "./Pages/Contact/Contact";
import Products from "./Pages/Products/Products";
import Partners from "./Pages/Partners/Partners";
import BlogDetail from "./Pages/BlogDetail/BlogDetail.js";
import { FloatingWhatsApp } from "react-floating-whatsapp";

import AdminPanel from "./Pages/AdminPanel/AdminPanel.jsx";
import CreateUser from "./Pages/CreateUser/CreateUser.js";
import EditUser from "./Pages/EditUser/EditUser.js";
import Dashboard from "./Pages/Dashboard/Dashboard.jsx";
import Protected from "./Pages/Protected/Protected.js";
import Blog from "./Pages/Blog/Blog.js";
import Portfolio from "./Pages/Portfolio/Portfolio.js";
import { ToastContainer } from "react-toastify";
import DomenHostinq from "./Pages/AllServices/DomenHostinq/DomenHostinq.js";
import GoogleSeo from "./Pages/AllServices/GoogleSeo/GoogleSeo.js";
import VebsaytTexniki from "./Pages/AllServices/VebsaytTexniki/VebsaytTexniki.js";
import KorporativPoct from "./Pages/AllServices/KorporativPoct/KorporativPoct.js";
import OdemeInteqrasiya from "./Pages/AllServices/OdemeInteqrasiya/OdemeInteqrasiya.js";
import SaytHazirlama from "./Pages/AllServices/SaytHazirlama/SaytHazirlama.js";

const Layout = () => {
  return ( 
    <div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover 
        theme="colored"
      /> 
      <AboveHeader />
      <Header />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/products" element={<Products />}></Route>
        <Route path="/partners" element={<Partners />}></Route>
        <Route path="/blog" element={<Blog />}></Route>
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/portfolio" element={<Portfolio />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/saytlarin-hazirlanmasi" element={<SaytHazirlama />}></Route>
        <Route path="/domen-hostinq" element={<DomenHostinq />} />
        <Route path="/google-seo" element={<GoogleSeo />} />
        <Route path="/vebsayt-texniki" element={<VebsaytTexniki />} />
        <Route path="/korporativ-poct" element={<KorporativPoct />} />
        <Route path="/odeme-inteqrasiya" element={<OdemeInteqrasiya />} />
        <Route path="/admin" element={<AdminPanel />} />
          <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
      <Route path="dashboard/create"  element={<Protected Component={CreateUser} />} />
      <Route path="dashboard/:id/edit"  element={<Protected Component={EditUser} />} />
      </Route>
    </Route>
  )
);



function App() {
  
  useEffect(() => {
    // Tawk.to Script
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script');
      var s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/66d8b798ea492f34bc0dbea7/1i6v8qau3';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
  
      // Log for debugging
      console.log('Tawk.to script added.');
    })();
  }, []);
  

  return (
    <div className="">
      <div className="font-bodyFont">
        <div>
        <FloatingWhatsApp
  buttonStyle={{ marginBottom: '70px', right: '25px' }} // Sağdan 20px boşluk bıraktık
  phoneNumber="+994516834218"
  accountName="Prosayt"
  statusMessage=""
  chatMessage="Salam, sizə necə kömək edə bilərik?"
  placeholder="Mesaj yazın..."
  avatar="./avatar.png"
  messageDelay="1"
/>
          <RouterProvider router={router} />
        </div> 
      </div>
    </div>
  );
}

export default App;
