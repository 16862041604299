import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { avatar } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const teamMembers = [
  { name: "Əli Əliyev", title: "Lorem ipsum dolo sit amet", image: avatar },
  { name: "Əli Əliyev", title: "Lorem ipsum dolo sit amet", image: avatar },
  { name: "Əli Əliyev", title: "Lorem ipsum dolo sit amet", image: avatar},
  { name: "Əli Əliyev", title: "Lorem ipsum dolo sit amet", image: avatar },
  { name: "Əli Əliyev", title: "Lorem ipsum dolo sit amet", image: avatar },
];

const Team = () => {

  const { t, i18n } = useTranslation();
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="py-12  bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-[#086170] tracking-wide uppercase">{t("Komandamız")}</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
           {t("Komandamız ilə Tanış Olun!")}
          </p>
        </div>
        <div class="flex lg:flex-row flex-col justify-between gap-8 pt-12 mx-auto">
        <div class="w-full lg:w-5/12 flex flex-col justify-center">
            <h1 class="text-3xl lg:text-4xl font-bold leading-9 text-[#086170] dark:text-white pb-4">{t("Komandamız")}</h1>
            <p class="font-normal text-base leading-6 text-gray-600 dark:text-white">{t("Komandamız peşəkar mütəxəssislərdən ibarətdir və hər bir üzv öz sahəsində geniş təcrübəyə malikdir. Bizim proqramçılar, dizaynerlər və texniki dəstək mütəxəssislərimiz layihələrin hər mərhələsində müştərilərimizə keyfiyyətli və etibarlı həllər təqdim edir. Komandamız hər zaman yeniliklərə açıqdır və müasir texnologiyalardan istifadə edərək müştərilərin ehtiyaclarına uyğun fərdi yanaşmalar hazırlayır. Məqsədimiz, hər bir layihənin müvəffəqiyyətlə başa çatdırılması və müştərilərimizin rəqabətədavamlı, funksional və innovativ rəqəmsal məhsullarla təmin olunmasıdır.")}</p>
        </div>
        <div class="w-full lg:w-8/12 lg:pt-8">
            <div class="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg rounded-md">
                <div class="p-4 pb-6 flex justify-center flex-col items-center">
                    <img class="md:block hidden" src={avatar} alt="Alexa featured Image" />
                    <img class="md:hidden block" src={avatar} alt="Alexa featured Image" />
                    <p class="font-medium text-xl leading-5 text-gray-800 dark:text-white mt-4">Lorem</p>
                </div>
                <div class="p-4 pb-6 flex justify-center flex-col items-center">
                    <img class="md:block hidden" src={avatar} alt="Olivia featured Image" />
                    <img class="md:hidden block" src={avatar} alt="Olivia featured Image" />
                    <p class="font-medium text-xl leading-5 text-gray-800 dark:text-white mt-4">Lorem</p>
                </div>
                <div class="p-4 pb-6 flex justify-center flex-col items-center">
                    <img class="md:block hidden" src={avatar} alt="Liam featued Image" />
                    <img class="md:hidden block" src={avatar} alt="Liam featued Image" />
                    <p class="font-medium text-xl leading-5 text-gray-800 dark:text-white mt-4">Lorem</p>
                </div>
                <div class="p-4 pb-6 flex justify-center flex-col items-center">
                    <img class="md:block hidden" src={avatar} alt="Elijah featured image" />
                    <img class="md:hidden block" src={avatar} alt="Elijah featured image" />
                    <p class="font-medium text-xl leading-5 text-gray-800 dark:text-white mt-4">Lorem</p>
                </div>
            </div>
        </div>
    </div>
      </div>
    </div>
  );
}

export default Team;
