

import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { map } from '../../assets/index'
import { CiMail } from "react-icons/ci";
import Heading from "../../Components/home/Heading";
import { useTranslation } from "react-i18next";
import ContactContent from "../../Components/ContactContent/ContactContent";


const Contact = () => {

  const { t, i18n } = useTranslation();
  return (
    <>
    <Heading heading={t("Əlaqə")} title={t("Əsas")} subtitle={t("Əlaqə")} />
    <ContactContent/>
    </>

  );
};

export default Contact;
