import React, { useEffect } from "react";
import Heading from "../../../Components/home/HeadingServices";
import AboutUs from '../../../Components/home/About/About';
import Team from "../../../Components/home/Team/Team";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { bgPhoto, muhasibatliq } from "../../../assets/index";
import { useTranslation } from "react-i18next";

export default function VebsaytTexniki() {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <>
    <div className="relative bg-gray-900 bg-opacity-80 text-white h-80 flex flex-col items-center justify-center">
      <img src={muhasibatliq} alt="Background" className="absolute inset-0 w-full h-full object-cover opacity-40" />
      <div className="relative z-10 text-center px-4">
        <h1 className="text-5xl font-extrabold mt-2 tracking-tight">{t("Vebsaytlara texniki dəstək")}</h1>
        <div className='mt-5 flex items-center justify-center space-x-2'>
        <i class="fa-solid fa-house"></i>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">{t("Əsas")}</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">{t("Xidmətlər")}</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <span className="text-sm uppercase tracking-wide text-gray-300">{t("Vebsaytlara texniki dəstək")}</span>
        </div>
      </div>
    </div>
      {/* <Heading heading="Mühasibatlıq" title="Əsas" title2='Xidmətlər' subtitle="Mühasibatlıq" /> */}
      <div className="flex flex-col items-center mt-12 mb-8">
      <div className="w-full sml:w-[80%]">
          <div data-aos="fade-up" className="flex flex-col md:flex-row rounded-lg p-6">
            <div className="p-6 flex flex-col justify-center ml-10">
              <h1 className="text-2xl font-bold mb-4 text-[#086170]">{t("Vebsaytlara texniki dəstək")}</h1>
              <p className="mb-4">
  {t("Prosayt.az, vebsaytlarınızın fasiləsiz və təhlükəsiz işləməsi üçün tam texniki dəstək xidmətləri göstərir. Bizim texniki dəstək xidmətimiz vebsaytın performansını artırmaq, yeniləmələr etmək və potensial problemləri tez bir zamanda həll etmək üçün nəzərdə tutulub. Aşağıdakı xidmətləri təklif edirik:")}
  <ul className="list-disc ml-5 mt-2">
      <li>{t("Saytların təhlükəsizlik yeniləmələri və müdafiəsi.")}</li>
      <li>{t("Texniki problemlərin diaqnozu və həlli.")}</li>
      <li>{t("Verilənlər bazası və server dəstəyi.")}</li>
      <li>{t("Vebsaytın optimizasiyası və performansın artırılması.")}</li>
  </ul>
</p>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
