import './product.css'
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { barkodOxuyucu, barkodPrinter } from '../../assets/index';
import { useTranslation } from 'react-i18next';

export const products = [
    {
      image: barkodOxuyucu,
      name: "Pul qutuları",
      discription: "Contrary to popular belief, ipsum is not simply random.",
      path: "/pul-qutulari"
    },
    {
      image: barkodPrinter,
      name: "All-In-One",
      discription: "Contrary to popular belief, ipsum is not simply random.",
      path: "/all-in-one"
    },
    {
      image: barkodOxuyucu,
      name: "Barkod Prosessor",
      discription: "Contrary to popular belief, ipsum is not simply random.",
      path: "/barkod-processor"
    },
  
    {
      image: barkodPrinter,
      name: "Card Reader",
      discription: "Contrary to popular belief, ipsum is not simply random.",
      path: "/card-reader"
    },
    {
      image: barkodOxuyucu,
      name: "Data Terminal",
      discription: "Contrary to popular belief, ipsum is not simply random.",
      path: "/data-terminal"
    },
  
    {
      image: barkodPrinter,
      name: "Barkod Oxuyucu",
      discription: "Contrary to popular belief, ipsum is not simply random.",
      path: "/barkod-oxuyucu"
    },
    {
      image: barkodPrinter,
      name: "Pos Printer",
      discription: "Contrary to popular belief, ipsum is not simply random.",
      path: "/pos-printer"
    },
    {
      image: barkodPrinter,
      name: "Barkod Tərəzi",
      discription: "Contrary to popular belief, ipsum is not simply random.",
      path: "/barkod-terezi"
    },
  
  ];

const Product = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div className="container-xxl py-5">
        <div className="container w-full sml:w-[80%]">
          <div className='container'>
      <div className='content grid3'>
        {products.map((val, index) => {
          const { image, category, name, path } = val;
          return (
            <div  data-aos="fade-up" className='box shadow' key={index}>
              
              <div className='img'>
                <img src={image} alt={name} />
              </div>
              <div className='text'>
                <div className='category flex'>
                  <span style={{backgroundColor: 'white'}}></span>
                  <i className='fa fa-heart'></i>
                </div>
                <h4>{t(name)}</h4>
                <p>
                  <i style={{color: '#086170'}} className='fa-solid fa-location-dot'></i> {t("Bakı, Azərbaycan")}
                </p>
                <a href={path}><button className='details-button'>{t("Ətraflı")}</button></a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
        </div>
      </div>
  );
};

export default Product;
