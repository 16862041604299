import React from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Team from "../../Components/home/Team/Team";
import Preferences from '../../Components/home/Preferences/Preferences';
import { useTranslation } from "react-i18next";
import ContactContent from "../../Components/ContactContent/ContactContent";

export default function About() {

  const { t, i18n } = useTranslation();
  return (
    <>
      <Heading heading={t("Haqqımızda")} title={t("Əsas")} subtitle={t("Haqqımızda")} />
      <AboutUs/>
      <Team/>
    </> 
  );
}
