import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const About = () => { 
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  
  return (
    <div className="flex flex-col items-center mt-12 mb-8">
    <div className="w-full sm:w-[100%] lg:w-[80%] px-4 sm:px-6 lg:px-0">
      <div data-aos="fade-up" className="flex flex-col md:flex-row rounded-lg p-4 sm:p-6 bg-white">
        <div className="md:w-1/2 flex justify-center md:justify-start">
          <img className='max-h-80 md:max-h-96 mt-4 md:mt-10 w-full object-contain' src={about} alt='About'></img>
        </div>
        <div className="md:w-1/2 p-4 sm:p-6 flex flex-col justify-center mt-6 md:mt-0 md:ml-10">
          <h1 className="text-xl sm:text-2xl font-bold mb-4 text-[#086170]">{t("Haqqımızda")}</h1>
          <h1 className="text-xl sm:text-2xl font-bold mb-4">{t("“PROSAYT” MMC haqqında məlumat")}</h1>
          <p className="text-sm sm:text-base mb-4">
           {t("Prosayt şirkəti olaraq, 7 illik təcrübəmizlə yüksək keyfiyyətli və sürətli vebsaytların hazırlanması sahəsində fəaliyyət göstəririk. Müxtəlif sahələr üzrə 200-dən çox uğurla tamamlanmış layihəmiz mövcuddur. Biz müştərilərimizə yalnız vebsayt yaradılması deyil, eyni zamanda domen və hosting satışı, korporativ poçt xidmətləri və texniki dəstək kimi geniş çeşidli xidmətlər də təklif edirik. Məqsədimiz, hər bir müştərimiz üçün fərdi ehtiyacları nəzərə alaraq, mükəmməl həllər təqdim etmək və onların onlayn uğurunu təmin etməkdir.")}
          </p>
          <ul className="list-none grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2">
            <li className="flex items-center">
              <i className="fa-solid fa-check mr-2 text-[#086170]"></i>
              {t("Konfidensiallıq")}
            </li>
            <li className="flex items-center">
              <i className="fa-solid fa-check mr-2 text-[#086170]"></i>
              {t("Operativlik")}
            </li>
            <li className="flex items-center">
              <i className="fa-solid fa-check mr-2 text-[#086170]"></i>
              {t("Dəqiqlik")}
            </li>
            <li className="flex items-center">
              <i className="fa-solid fa-check mr-2 text-[#086170]"></i>
              {t("Keyfiyyət")}
            </li>
            <li className="flex items-center">
              <i className="fa-solid fa-check mr-2 text-[#086170]"></i>
              {t("İndividuallıq")}
            </li>
            <li className="flex items-center">
              <i className="fa-solid fa-check mr-2 text-[#086170]"></i>
              {t("Peşəkarlıq")}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default About;
