import React from "react";
import BannerImg from "./about-44.jpg";
import { FaUserTie, FaUsers, FaLightbulb } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { blog6 } from "../../../assets";

const WhyUs = () => {

  const { t, i18n } = useTranslation();
  return (
    <>
      <span id="about"></span>
      <div>
        <div className="min-h-[550px] flex justify-center items-center py-12 md:py-0">
          <div className="container">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-[90%] md:w-[80%] mx-auto">
              <div className="flex flex-col justify-center gap-6 md:pt-0">
                <h1
                  data-aos="fade-up"
                  className="text-3xl md:text-4xl font-bold font-cursive"
                >
                  {t("Niyə Biz?")}
                </h1>
                <p
                  data-aos="fade-up"
                  className="text-md md:text-base text-gray-500 tracking-wide leading-5"
                >
                  {t("Biz müştərilərimizə yalnız vebsayt yaradılması deyil, eyni zamanda domen və hosting satışı, korporativ poçt xidmətləri və texniki dəstək kimi geniş çeşidli xidmətlər də təklif edirik. Məqsədimiz, hər bir müştərimiz üçün fərdi ehtiyacları nəzərə alaraq, mükəmməl həllər təqdim etmək və onların onlayn uğurunu təmin etməkdir.")}
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-5">
                    <div data-aos="fade-up" className="flex items-center gap-3">
                      <FaUserTie className="text-white text-2xl h-12 w-12 shadow-md p-3 rounded-full bg-[#086170]" />
                      <span className="text-md md:text-base">{t("Müştərilərə fərdi yanaşma")}</span>
                    </div>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="300"
                      className="flex items-center gap-3"
                    >
                      <FaUsers className="text-white text-2xl h-12 w-12 shadow-md p-3 rounded-full bg-[#086170]" />
                      <span className="text-md md:text-base">{t("Peşəkar və təcrübəli işçilər")}</span>
                    </div>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="500"
                      className="flex items-center gap-3"
                    >
                      <FaLightbulb className="text-white text-2xl h-12 w-12 shadow-md p-3 rounded-full bg-[#086170]" />
                      <span className="text-md md:text-base">{t("İnnovativ və modern həllər")}</span>
                    </div>
                  </div>
                  <div
                    className="border-l-4 border-primary/50 pl-6 space-y-2"
                  >
                    <h1 className="text-2xl font-semibold font-cursive">
                      {t("Peşəkarlıq")}
                    </h1>
                    <p className="text-md md:text-base text-gray-500">
                    {t("“PROSAYT” Məhdud Məsuliyyətli Cəmiyyəti yerli Azərbaycan şirkəti olaraq 2017-ci ilin aprel ayının 21-də fəaliyyətə başlamışdır.")}
                    </p>
                  </div>
                </div>
              </div>
              {/* Görsel bölümü */}
              <div data-aos="zoom-in" className="md:mt-0 ml-20 mt-40 flex justify-center items-center">
                <img
                  src={blog6}
                  alt="biryani img"
                  className="max-w-full w-full mx-auto"
                />
              </div>
              {/* Metin içeriği bölümü */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUs;
