import React, { useEffect } from "react";
import Heading from "../../../Components/home/HeadingServices";
import AboutUs from '../../../Components/home/About/About';
import Team from "../../../Components/home/Team/Team";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { muhasibatliq, photo3, vergi } from "../../../assets";
import { useTranslation } from "react-i18next";

export default function OdemeInteqrasiya() {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <>
      <div className="relative bg-gray-900 bg-opacity-80 text-white h-80 flex flex-col items-center justify-center">
      <img src={vergi} alt="Background" className="absolute inset-0 w-full h-full object-cover opacity-40" />
      <div className="relative z-10 text-center px-4">
        <h1 className="text-5xl font-extrabold mt-2 tracking-tight">{t("Ödəmə sistemlərinin inteqrasiyası")}</h1>
        <div className='mt-5 flex items-center justify-center space-x-2'>
        <i class="fa-solid fa-house"></i>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">{t("Əsas")}</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">{t("Xidmətlər")}</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <span className="text-sm uppercase tracking-wide text-gray-300">{t("Ödəmə sistemlərinin inteqrasiyası")}</span>
        </div>
      </div>
    </div>
      <div className="flex flex-col items-center mt-12 mb-8">
      <div className="w-full sml:w-[80%]">
          <div data-aos="fade-up" className="flex flex-col md:flex-row rounded-lg p-6">
            <div className="p-6 flex flex-col justify-center ml-10">
              <h1 className="text-2xl font-bold mb-4 text-[#086170]">{t("Ödəmə sistemlərinin inteqrasiyası")}</h1>
              <p className="mb-4">
  {t("Prosayt.az, müasir ödəniş sistemlərinin inteqrasiyasını təmin edərək, biznesinizin müştəriyə rahatlıqla xidmət etməsini hədəfləyir. Biz, müxtəlif ödəniş metodları ilə müştərilərinizin alış-veriş təcrübəsini asanlaşdırırıq. Aşağıdakı xidmətləri təqdim edirik:")}
  <ul className="list-disc ml-5 mt-2">
      <li>{t("Fərdi ehtiyaclara uyğun ödəniş sistemlərinin seçimi.")}</li>
      <li>{t("Ödəniş qapılarının inteqrasiyası.")}</li>
      <li>{t("Onlayn ödənişlərin təhlükəsizliyi və idarə edilməsi.")}</li>
      <li>{t("Müştəri məlumatlarının qorunması üçün əlavə təhlükəsizlik tədbirləri.")}</li>
  </ul>
</p>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
