import React from "react";
import Heading from "../../Components/home/Heading";
import Partner from "../../Components/home/Partners/Partners";
import { useTranslation } from "react-i18next";

export const price = [
  {
    plan: "Market",
    price: "29",
    ptext: "per user, per month",
    image: "../assets/img/partners/partners1.png",
    path:'https://hediyyem.az/'
  },
  {
    best: "Best Value",
    plan: "Restoran",
    price: "49",
    ptext: "per user, per month",
    image: "../assets/img/partners/partners2.png",
    path: 'https://viplife.az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners3.png",
    path: 'https://tezbazar.az/'
  },
  {
    plan: "Market",
    price: "29",
    ptext: "per user, per month",
    image: "../assets/img/partners/partners4.png",
    path: 'https://usel.az/az'
  },
  {
    best: "Best Value",
    plan: "Restoran",
    price: "49",
    ptext: "per user, per month",
    image: "../assets/img/partners/partners5.png",
    path: 'https://sebet.az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners6.png",
    path: 'https://kompass.az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners7.png",
    path: 'https://timsport.az/az'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners8.png",
    path: 'https://www.themerchantbaku.com/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners9.png",
    path: 'https://novikovcafebaku.online/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners10.png",
    path: 'https://flowwow.az/en/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners11.png",
    path: 'https://localrent.com/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners12.png",
    path: 'https://cafecity.az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners13.png",
    path: 'https://mimelon.com/az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners144.png",
    path: 'https://galaalti.az/en'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners155.png",
    path: 'https://yurdhotel.az/az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners16.png",
    path: 'https://coffeebar.az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners17.png",
    path: 'https://coffeehubbaku.com/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners18.png",
    path: 'https://tut.az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners19.png",
    path: 'https://totalex.az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners20.png",
    path: 'https://interfood.az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners21.png",
    path: 'https://ustalar.az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners22.png",
    path: 'https://priorleasing.az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners23.png",
    path: 'https://cleverest.az/'
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners24.png",
    path: 'https://qafqazxaricdetehsil.az/'
  },

]

export default function Partners() {

  const { t, i18n } = useTranslation();
  return (
    <>
      <Heading heading={t("Portfolio")} title={t("Əsas")} subtitle={t("Portfolio")} />
      <div className='price container w-[80%]'>
      <div className='corporation content flex mtop'>
        {price.map((item, index) => (
            <div className='box shadoww' key={index}>
            <a href={item.path}>
            <img src={item.image} alt={`item-${index}`} />
            </a>
          </div>
        ))}
      </div>
    </div>
    </>
  );
}