import React, { useEffect } from "react";
import Heading from "../../../Components/home/HeadingServices";
import AboutUs from '../../../Components/home/About/About';
import Team from "../../../Components/home/Team/Team";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { insanResurslari, muhasibatliq } from "../../../assets";
import { useTranslation } from "react-i18next";

export default function DomenHostinq() {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <>
       <div className="relative bg-gray-900 bg-opacity-80 text-white h-80 flex flex-col items-center justify-center">
      <img src={insanResurslari} alt="Background" className="absolute inset-0 w-full h-full object-cover opacity-40" />
      <div className="relative z-10 text-center px-4">
        <h1 className="text-5xl font-extrabold mt-2 tracking-tight">{t("Domen və hostinq satışı")}</h1>
        <div className='mt-5 flex items-center justify-center space-x-2'>
        <i class="fa-solid fa-house"></i>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">{t("Əsas")}</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">{t("Xidmətlər")}</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <span className="text-sm uppercase tracking-wide text-gray-300">{t("Domen və hostinq satışı")}</span>
        </div>
      </div>
    </div>
      <div className="flex flex-col items-center mt-12 mb-8">
      <div className="w-full sml:w-[80%]">
          <div data-aos="fade-up" className="flex flex-col md:flex-row rounded-lg p-6">
          <div className="p-6 flex flex-col justify-center ml-10">
  <h1 className="text-2xl font-bold mb-4 text-[#086170]">{t("Domen və hostinq satışı")}</h1>
  <p className="mb-4">
    {t("Domen və hostinq xidmətlərimiz aşağıdakılardır:")}
  </p>
  <ul className="list-disc list-inside mb-4">
    <li>{t("Etibarlı domen qeydiyyatı xidməti")}</li>
    <li>{t("Yüksək sürətli və təhlükəsiz hostinq həlləri")}</li>
    <li>{t("Domen adlarının transferi və idarə olunması")}</li>
    <li>{t("Limitsiz e-poçt hesablarının yaradılması")}</li>
    <li>{t("SSL sertifikatlarının quraşdırılması və təmin edilməsi")}</li>
    <li>{t("Bulud server xidmətləri ilə maksimum sürət və təhlükəsizlik")}</li>
    <li>{t("Domen adlarının avtomatik yenilənməsi və idarə edilməsi")}</li>
    <li>{t("Hostinq planlarının fərdiləşdirilməsi və ehtiyat nüsxə xidmətləri")}</li>
    <li>{t("99.9% uptime zəmanəti ilə fasiləsiz fəaliyyət")}</li>
    <li>{t("Texniki dəstək və müştəri xidməti 24/7")}</li>
  </ul>
</div>

          </div>
        </div>
      </div>
    </>
  );
}
