import React from 'react';
import Carousel from '../../Components/home/Carousel/Carousel';
import Preferences from '../../Components/home/Preferences/Preferences';
import About from '../../Components/home/About/About';
import WhyUs from '../../Components/home/WhyUs/WhyUs';
import Partners from '../../Components/home/Partners/Partners';
import Contact from '../../Components/home/Contact/Contact';
import Team from '../../Components/home/Team/Team';
import Awards from '../../Components/home/Awards/Awards';
import ServicesShort from '../../Components/home/ServicesShort/ServicesShort';
import ProductShort from '../../Components/home/ProductShort/ProductShort';



const Home = () => {
  return (
    <>
    <Carousel/>
    <About/>
    <Preferences/>
    <WhyUs/>
    <Partners/>
    {/* <ProductShort/> */}
    <Awards/>
    <ServicesShort/>
    <Contact/>
    <Team/>
    
    
     {/* <img src='./homepage.png'></img> 42455E */}
    </>
  )
}

export default Home;
